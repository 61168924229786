import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import routesMap from '../../../Routes';
import withOrderContext from '../../../withOrderContext';
import { trackEvent } from '../../../services/analytics';
import OrderContainer from '../../common/OrderContainer';
import Step1 from '../../../components/order/Step1';
import OrderSummaryContainer from '../../../components/order/OrderSummaryContainer';
import { multipleQuestions } from '../../../components/order/tree';

const scrollToStep = (ref) => {
  ref.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'start',
  });
};

const shouldDisplayLining = (selectedProblems) => {
  if (!selectedProblems) return false;

  const selectedProblemsToCheckForLining = Object.keys(selectedProblems)
    .filter(problem => problem !== 'talkRDV')
    .map(problem => selectedProblems[problem]);

  return selectedProblemsToCheckForLining.length > 0
    ? selectedProblemsToCheckForLining.reduce((acc, selectedProblem) => acc &&
      selectedProblem.length > 0 &&
      (typeof selectedProblem[0] !== 'object' || selectedProblem[0].value !== -1)
      , true)
    : false;
};

const initialState = {
  selectedCloth: '',
  selectedFabric: '',
  selectedProblems: {},
  selectedLining: '',
};

class Step1Container extends Component {
  constructor(props) {
    super(props);
    const { orderContext: { editingCloth } } = props;
    this.state = { ...initialState, ...editingCloth };
    this.setEditingCloth = this.setEditingCloth.bind(this);
    this.setSelectValue = this.setSelectValue.bind(this);
    this.getClothesForSummary = this.getClothesForSummary.bind(this);
    this.getSelectedClothesForSummary = this.getSelectedClothesForSummary.bind(this);
    this.selectCloth = this.selectCloth.bind(this);
    this.selectFabric = this.selectFabric.bind(this);
    this.selectProblem = this.selectProblem.bind(this);
    this.selectLocation = this.selectLocation.bind(this);
    this.selectLining = this.selectLining.bind(this);
    this.saveCloth = this.saveCloth.bind(this);
    this.displaySummary = this.displaySummary.bind(this);
    this.goNext = this.goNext.bind(this);
    this.addPiece = this.addPiece.bind(this);
  }

  componentDidMount() {
    const { selectedCloth, selectedFabric } = this.state;
    if (selectedCloth && selectedFabric) {
      setTimeout(() => {
        scrollToStep(this.Step1Problems);
      }, 500);
    }
  }

  setEditingCloth() {
    const { orderContext: { editingCloth } } = this.props;
    this.setState({
      ...initialState,
      ...editingCloth,
    });
  }

  setSelectValue(selectedValue, slug) {
    const problems = { ...this.state.selectedProblems };
    problems[slug] = [selectedValue];
    this.setState({ problems });
  }

  getClothesForSummary() {
    const { clothes = [], editingClothIndex } = this.props.orderContext;
    if (typeof editingClothIndex === 'number') {
      return clothes.map((cloth, index) => index === editingClothIndex ? this.state : cloth);
    }
    return [...clothes, this.state];
  }

  getSelectedClothesForSummary() {
    const { clothes = [] } = this.props.orderContext;
    return clothes;
  }

  goNext() {
    this.saveCloth();
    this.setState(initialState);
    navigate(routesMap.Step2.url);
  }

  addPiece() {
    this.saveCloth();
    this.setState(initialState);
    scrollTo(0, 0);
  }

  selectCloth(slug) {
    trackEvent('Order Funnel - Item Chosen', slug);
    this.setState({ ...initialState, selectedCloth: slug }, () => scrollToStep(this.Step1Fabrics));
  }

  selectFabric(slug) {
    trackEvent('Order Funnel - Fabric Chosen', slug);
    const { orderContext: { isFastVariant } } = this.props;
    this.setState({ selectedFabric: slug }, () => {
      if (isFastVariant) {
        this.goNext();
      } else {
        scrollToStep(this.Step1Problems);
      }
    });
  }

  selectProblem(problemSlug) {
    let problems = { ...this.state.selectedProblems };
    const locationSlugs = problems[problemSlug];
    if (typeof locationSlugs === 'object') {
      problems[problemSlug].forEach((location) => {
        if (Object.keys(problems).includes(`${location}_multiple`)) {
          delete problems[`${location}_multiple`];
        }
      });
      delete problems[problemSlug];
      if (!shouldDisplayLining(problems)) {
        this.setState({ lining: '' });
      }
    } else {
      problems = {
        ...problems,
        [problemSlug]: problemSlug.includes('acce') ? [{ value: 1, label: '1' }] : [],
      };
    }
    this.setState({
      selectedProblems: { ...problems },
    });
  }

  selectLocation(problemSlug, locationSlug) {
    let problems = { ...this.state.selectedProblems };
    const slugIndex = problems[problemSlug].findIndex(location => location === locationSlug);
    if (slugIndex >= 0) {
      problems[problemSlug].splice(slugIndex, 1);
      if (Object.keys(multipleQuestions).includes(locationSlug)) {
        delete problems[`${locationSlug}_multiple`];
      }
      if (!shouldDisplayLining(problems)) {
        this.setState({ lining: '' });
      }
    } else {
      problems[problemSlug].push(locationSlug);
      if (Object.keys(multipleQuestions).includes(locationSlug)) {
        problems = { ...problems, [`${locationSlug}_multiple`]: [{ value: 1, label: '1' }] };
      }
    }
    this.setState({
      selectedProblems: { ...problems },
    });
  }

  selectLining(haveLining) {
    this.setState({
      selectedLining: haveLining,
    });
  }

  saveCloth() {
    const { addOrEditCloth } = this.props.orderContext;
    if (this.isSelectingDone()) {
      const {
        selectedCloth,
        selectedFabric,
        selectedProblems,
        selectedLining,
      } = this.state;
      addOrEditCloth({
        selectedCategory: 'clothes',
        selectedCloth,
        selectedFabric,
        selectedTypeOfWork: 'alteration',
        selectedProblems,
        selectedLining,
      });
    }
  }

  isSelectingDone() {
    const {
      selectedCloth,
      selectedFabric,
      selectedProblems,
      selectedLining,
    } = this.state;
    const { orderContext: { isFastVariant } } = this.props;

    if (isFastVariant && selectedCloth && selectedFabric) return true;
    if (selectedCloth === 'acce') {
      if (Object.keys(selectedProblems).length === 0) return false;
      if (selectedProblems.damaged) return selectedProblems.damaged.length > 0;
      return true;
    }
    return (Object.keys(selectedProblems).length === 1 && Object.keys(selectedProblems)[0] === 'talkRDV')
      || selectedLining !== '';
  }

  displaySummary() {
    const { clothes = [] } = this.props.orderContext;
    if (this.isSelectingDone()) {
      return (
        <OrderSummaryContainer
          clothes={this.getClothesForSummary()}
          setEditingCloth={this.setEditingCloth}
          saveCloth={this.saveCloth}
        />
      );
    } else if (clothes.length > 0) {
      return (
        <OrderSummaryContainer
          clothes={this.getSelectedClothesForSummary()}
          setEditingCloth={this.setEditingCloth}
          saveCloth={this.saveCloth}
        />
      );
    }
    return null;
  }

  render() {
    const {
      selectedCloth,
      selectedFabric,
      selectedProblems,
      selectedLining,
    } = this.state;
    const isLiningDisplayed = selectedCloth !== 'acce' ? shouldDisplayLining(selectedProblems) : false;
    return (
      <OrderContainer>
        <Step1
          cloth={selectedCloth}
          fabric={selectedFabric}
          problems={selectedProblems}
          lining={selectedLining}
          isLiningDisplayed={isLiningDisplayed}
          selectPiece={this.selectCloth}
          selectFabric={this.selectFabric}
          selectProblem={this.selectProblem}
          selectLocation={this.selectLocation}
          selectLining={this.selectLining}
          setSelectValue={this.setSelectValue}
          setRef={(key, value) => { this[key] = value; }}
          renderSummary={this.displaySummary}
          isSelectingDone={this.isSelectingDone()}
          goNext={this.goNext}
          addPiece={this.addPiece}
        />
      </OrderContainer>
    );
  }
}

Step1Container.propTypes = {
  orderContext: PropTypes.shape({
    addOrEditCloth: PropTypes.func.isRequired,
    clothes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    editingCloth: PropTypes.shape({}),
    editingClothIndex: PropTypes.number,
    isFastVariant: PropTypes.bool,
  }).isRequired,
};

export default withOrderContext(Step1Container);
